<template>
  <div id="transport_agent_panel">
    <div class="transport_agent_info_container">
      <div class="transport_agent_info_container__item header_container">
        <div class="header_container__item traspor_title_container">
          <div class="traspor_title_container__item">
            <div class="title_column_traspor_title_container__item">
              <div v-if="getAgent.isOnline == true" class="status online"></div>
              <div
                v-else-if="getAgent.isOnline == false"
                class="status offline"
              ></div>

              <div v-else class="status no_info"></div>
            </div>
          </div>
          <div class="traspor_title_container__item"></div>
          <label>{{ getAgent.agent }}</label>
        </div>
        <div class="header_container__item right_buttons_container">
          <div class="right_buttons_container__item">
            <button title="Экспорт" @click="downloadAgent">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 0.833984L3.66683 4.16732H6.16683V11.6673H7.8335V4.16732H10.3335M12.0002 19.1673H2.00016C1.55814 19.1673 1.13421 18.9917 0.821651 18.6792C0.509091 18.3666 0.333496 17.9427 0.333496 17.5006V7.50065C0.333496 7.05862 0.509091 6.6347 0.821651 6.32214C1.13421 6.00958 1.55814 5.83398 2.00016 5.83398H4.50016V7.50065H2.00016V17.5006H12.0002V7.50065H9.50016V5.83398H12.0002C12.4422 5.83398 12.8661 6.00958 13.1787 6.32214C13.4912 6.6347 13.6668 7.05862 13.6668 7.50065V17.5006C13.6668 17.9427 13.4912 18.3666 13.1787 18.6792C12.8661 18.9917 12.4422 19.1673 12.0002 19.1673Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div class="right_buttons_container__item">
            <button @click="$emit('closePanel')">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33317 11.8346L0.166504 10.668L4.83317 6.0013L0.166504 1.33464L1.33317 0.167969L5.99984 4.83464L10.6665 0.167969L11.8332 1.33464L7.1665 6.0013L11.8332 10.668L10.6665 11.8346L5.99984 7.16797L1.33317 11.8346Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        class="transport_agent_info_container__item transport_agent_content_container"
      >
        <div class="transport_agent_content_container__item">
          <div class="item_title">Установить коэффициент</div>
          <div class="item_status_bar">
            <action-bar
              :show="actionBarData.сoefficient.show"
              @close-action-bar="resetActionBar('сoefficient')"
              :status="actionBarData.сoefficient.status"
              :action="actionBarData.сoefficient.action"
              :broadDescription="actionBarData.сoefficient.broadDescription"
            />
          </div>
          <div class="item_content">
            <div class="сoefficient_container">
              <div class="сoefficient_container__item title">Коэффициент</div>
              <div class="сoefficient_container__item input_container">
                <div class="input_container__item">
                  <input
                    class="label_input"
                    :class="
                      !isValidCoefficient || settings.сoefficient.length === 0
                        ? 'invalid'
                        : ''
                    "
                    v-model="settings.сoefficient"
                    type="number"
                    min="0"
                    max="5"
                  />
                </div>
                <div class="input_container__item">
                  <button
                    @click="
                      !isValidCoefficient || settings.сoefficient.length === 0
                        ? null
                        : setCoefficient()
                    "
                    :class="
                      !isValidCoefficient || settings.сoefficient.length === 0
                        ? 'gray_button'
                        : 'blue_button'
                    "
                  >
                    Применить
                  </button>
                </div>
              </div>
              <div class="сoefficient_container__item">
                Доступные значения 0-5; 0 запрещает создание новых пиров
              </div>
            </div>
          </div>
        </div>
        <div class="transport_agent_content_container__item">
          <div class="item_title">Прикрепить терминалы к агенту</div>
          <div class="item_status_bar">
            <action-bar
              :show="actionBarData.pinTerminalsToAgent.show"
              @close-action-bar="resetActionBar('pinTerminalsToAgent')"
              :status="actionBarData.pinTerminalsToAgent.status"
              :action="actionBarData.pinTerminalsToAgent.action"
              :broadDescription="
                actionBarData.pinTerminalsToAgent.broadDescription
              "
            />
          </div>
          <div class="item_content">
            <div class="pin_terminals_to_agent_container">
              <div
                class="pin_terminals_to_agent_container__item input_container"
              >
                <div class="input_container__item">
                  <input
                    class="label_input"
                    :class="!isValidPinTerminalsToAgent ? 'invalid' : ''"
                    v-model="settings.pinTerminalsToAgent"
                    placeholder="Terminal ID через запятую"
                  />
                </div>
                <div class="pin_terminals_to_agent_container__item">
                  <button
                    @click="
                      isValidPinTerminalsToAgent ? pinTerminalsToAgent() : null
                    "
                    :class="
                      isValidPinTerminalsToAgent ? 'blue_button' : 'gray_button'
                    "
                  >
                    Применить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="transport_agent_content_container__item">
          <div class="item_title">Прикрепленные терминалы</div>
          <div v-if="pinnedTerminals.length > 0" class="item_status_bar">
            <action-bar
              :show="actionBarData.toUnpinTerminals.show"
              @close-action-bar="resetActionBar('toUnpinTerminals')"
              :status="actionBarData.toUnpinTerminals.status"
              :action="actionBarData.toUnpinTerminals.action"
              :broadDescription="
                actionBarData.toUnpinTerminals.broadDescription
              "
            />
          </div>
          <div class="item_content">
            <div
              v-if="pinnedTerminals.length > 0"
              class="item_content__item search_input"
            >
              <div class="search_input__item icon">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.50006 0C8.53765 0 11.0001 2.46256 11.0001 5.50029C11.0001 6.74868 10.5842 7.89993 9.88346 8.82304L13.7791 12.7233C14.0718 13.0164 14.0715 13.4913 13.7785 13.784C13.4854 14.0767 13.0105 14.0764 12.7178 13.7834L8.82266 9.88388C7.89959 10.5847 6.74839 11.0006 5.50006 11.0006C2.46246 11.0006 0 8.53802 0 5.50029C0 2.46256 2.46246 0 5.50006 0ZM5.50006 1.5C3.2909 1.5 1.5 3.29098 1.5 5.50029C1.5 7.70961 3.2909 9.50058 5.50006 9.50058C7.70921 9.50058 9.50011 7.70961 9.50011 5.50029C9.50011 3.29098 7.70921 1.5 5.50006 1.5Z"
                    fill="#A1A3AB"
                  ></path>
                </svg>
              </div>
              <div
                class="search_input_item search_text"
                :class="filterPinnedTerminals ? 'typed' : ''"
              >
                <input
                  @input="onInputPinTerminalFilter"
                  placeholder="Terminal ID через запятую"
                />
              </div>
            </div>
            <div class="item_content__item pin_table">
              <grid-table
                :items="this.getFilteredPinTerminals"
                @change-selected="
                  (event) => (this.settings.toUnpinTerminals = event)
                "
                ref="gridTablePinnedTerminals"
              />
            </div>
            <div
              v-if="pinnedTerminals.length > 0"
              class="item_content__item pin_terminal_button_container"
            >
              <div class="pin_terminal_button_container delete">
                <button
                  @click="
                    settings.toUnpinTerminals.length === 0
                      ? null
                      : unpinTerminalsFromAgent()
                  "
                  :class="
                    settings.toUnpinTerminals.length === 0
                      ? 'gray_button'
                      : 'blue_button'
                  "
                >
                  Удалить
                </button>
              </div>
              <div class="pin_terminal_button_container delete">
                <button
                  @click="this.$refs.gridTablePinnedTerminals.selectAll()"
                  :class="
                    settings.toUnpinTerminals.length ===
                    getFilteredPinTerminals.length
                      ? 'gray_button'
                      : 'blue_button'
                  "
                >
                  Выделить все
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="transport_agent_content_container__item">
          <div class="item_title">Подключенные терминалы</div>
          <div v-if="connectedTerminals.length > 0" class="item_status_bar">
            <action-bar
              :show="actionBarData.toUnconnectedTerminals.show"
              @close-action-bar="resetActionBar('toUnconnectedTerminals')"
              :status="actionBarData.toUnconnectedTerminals.status"
              :action="actionBarData.toUnconnectedTerminals.action"
              :broadDescription="
                actionBarData.toUnconnectedTerminals.broadDescription
              "
            />
          </div>
          <div class="item_content">
            <div
              v-if="connectedTerminals.length > 0"
              class="item_content__item search_input"
            >
              <div class="search_input__item icon">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.50006 0C8.53765 0 11.0001 2.46256 11.0001 5.50029C11.0001 6.74868 10.5842 7.89993 9.88346 8.82304L13.7791 12.7233C14.0718 13.0164 14.0715 13.4913 13.7785 13.784C13.4854 14.0767 13.0105 14.0764 12.7178 13.7834L8.82266 9.88388C7.89959 10.5847 6.74839 11.0006 5.50006 11.0006C2.46246 11.0006 0 8.53802 0 5.50029C0 2.46256 2.46246 0 5.50006 0ZM5.50006 1.5C3.2909 1.5 1.5 3.29098 1.5 5.50029C1.5 7.70961 3.2909 9.50058 5.50006 9.50058C7.70921 9.50058 9.50011 7.70961 9.50011 5.50029C9.50011 3.29098 7.70921 1.5 5.50006 1.5Z"
                    fill="#A1A3AB"
                  ></path>
                </svg>
              </div>
              <div
                class="search_input_item search_text"
                :class="filterConnectedTerminals ? 'typed' : ''"
              >
                <input
                  @input="onInputConnectedTerminalFilter"
                  placeholder="Terminal ID или статус"
                />
              </div>
            </div>
            <div class="item_content__item connected_table">
              <connected-table
                :terminals="getFilteredConnectedTerminals"
                @unconnect-terminals="
                  (event) => (this.settings.toUnconnectedTerminals = event)
                "
              />
            </div>
            <div
              v-if="connectedTerminals.length > 0"
              class="item_content__item delete"
            >
              <button
                :class="
                  settings.toUnconnectedTerminals.length === 0
                    ? 'gray_button'
                    : 'blue_button'
                "
              >
                Удалить
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
    // "connected-table": defineAsyncComponent(() =>
    //   import("@/components/TransportPanelTable.vue")
    // ),
    "grid-table": defineAsyncComponent(() =>
      import("@/components/GridTable.vue")
    ),
  },
  props: {
    agent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      actionBarData: {
        сoefficient: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        toUnconnectedTerminals: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        pinTerminalsToAgent: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
        toUnpinTerminals: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
      settings: {
        сoefficient: 1,
        pinTerminalsToAgent: null,
        toUnpinTerminals: [],
        toUnconnectedTerminals: [],
      },
      filterPinnedTerminals: [],
      pinnedTerminals: [],

      filterConnectedTerminals: [],
      connectedTerminals: [
        {
          terminalId: 11,
          state: "ERROR",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
        {
          terminalId: 6495,
          state: "OK",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
        {
          terminalId: 13,
          state: "ERROR",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
        {
          terminalId: 100000,
          state: "OK",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
        {
          terminalId: 4431,
          state: "ERROR",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
        {
          terminalId: 20,
          state: "OK",
          changed: "2022-12-23 07:43:55.645621+00:00",
        },
      ],
    };
  },
  methods: {
    downloadAgent() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.agent, null, 2));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `terminal_${this.agent.agent}_export` + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },

    onInputPinTerminalFilter(event) {
      const tempTerminals = [];
      if (event.target.value.length === 0) {
        this.filterPinnedTerminals = [];
        return;
      }
      for (let el of event.target.value.split(",")) {
        const num = el.length > 0 ? Number(el.trim()) : Number.NaN;
        if (!Number.isNaN(num)) {
          tempTerminals.push(num);
        }
      }

      this.filterPinnedTerminals = tempTerminals;
    },
    onInputConnectedTerminalFilter(event) {
      if (event.target.value.length === 0) {
        this.filterConnectedTerminals = [];
        return;
      }
      const tempTerminals = [];
      event.target.value.split(",").forEach((el) => {
        const terminalId = Number(el.trim());
        if (!Number.isNaN(terminalId)) {
          tempTerminals.push(terminalId);
          return;
        }
        if (["OK", "ERROR", "INPROCESS", "DELETE"].includes(el.trim())) {
          const terminals = this.connectedTerminals.filter(
            (locEl) => locEl.state === el.trim()
          );
          if (terminals.length > 0) {
            tempTerminals.push(...terminals.map((locEl) => locEl.terminalId));
          } else {
            tempTerminals.push(-1);
          }
          return;
        }
        tempTerminals.push(-1);
      });
      this.filterConnectedTerminals = tempTerminals;
    },

    getAgentRules() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (templates list received)",
            res
          );
          if (res.status === 200) {
            const tempTerminals = [];
            res.data.payload.terminalRules.forEach((el) => {
              if (el.agent === this.agent.agent) {
                tempTerminals.push(el.terminalId);
              }
            });
            this.pinnedTerminals = tempTerminals;
          }
        })
        .catch((error) => {
          console.log("Error to get terminal list", error);
        });
      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    setCoefficient() {
      this.actionBarData.сoefficient = {
        status: "WAITING",
        action: "Установка коэффициента",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/agents/rules`,
          method: "POST",
          payload: {
            rate: this.settings.сoefficient,
            agent: this.agent.agent,
            action: "SET",
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (set rate options)",
            res
          );

          if (200 == res.data.statusCode) {
            this.actionBarData.сoefficient.status = "OK";
            this.$emit("updateAgent");
            setTimeout(() => {
              this.resetActionBar("сoefficient");
            }, 4000);
            return;
          }

          this.actionBarData.сoefficient.status = "ERROR";
          this.actionBarData.сoefficient.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to set rate options", error);
          if (error.request.status === 403) {
            this.actionBarData.сoefficient.status = "ERROR";
            (this.actionBarData.сoefficient.action = "Сохранение"),
              (this.actionBarData.сoefficient.broadDescription =
                "Недостаточно прав");
            return;
          }
          this.actionBarData.сoefficient.status = "ERROR";
          this.actionBarData.сoefficient.broadDescription = error.data;
        });

      // .finally(() => {
      //   this.showLoad = false;
      // });
    },
    pinTerminalsToAgent() {
      this.actionBarData.pinTerminalsToAgent = {
        status: "WAITING",
        action: "Прикрепление",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "POST",
          payload: {
            terminalIds: this.getPinTerminalsToAgent,
            agent: this.agent.agent,
            action: "SET",
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (pin terminals to agent)",
            res
          );

          if (200 == res.data.statusCode) {
            this.actionBarData.pinTerminalsToAgent.status = "OK";
            this.$emit("updateAgent");
            setTimeout(() => {
              this.resetActionBar("pinTerminalsToAgent");
            }, 4000);
            return;
          }

          this.actionBarData.pinTerminalsToAgent.status = "ERROR";
          this.actionBarData.pinTerminalsToAgent.broadDescription =
            res.data.payload;
        })
        .catch((error) => {
          console.log("Error to pin terminals to agents", error);
          if (error.request.status === 403) {
            this.actionBarData.pinTerminalsToAgent.status = "ERROR";
            (this.actionBarData.pinTerminalsToAgent.action = "Сохранение"),
              (this.actionBarData.pinTerminalsToAgent.broadDescription =
                "Недостаточно прав");
            return;
          }
          this.actionBarData.pinTerminalsToAgent.status = "ERROR";
          this.actionBarData.pinTerminalsToAgent.broadDescription = error.data;
        });

      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    unpinTerminalsFromAgent() {
      this.actionBarData.toUnpinTerminals = {
        status: "WAITING",
        action: "Открепление",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/terminals/rules`,
          method: "POST",
          payload: {
            terminalIds: this.settings.toUnpinTerminals,
            agent: this.agent.agent,
            action: "DELETE",
          },
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (delete terminals from agent)",
            res
          );

          if (200 == res.data.statusCode) {
            this.actionBarData.toUnpinTerminals.status = "OK";
            this.settings.toUnpinTerminals = [];
            this.$emit("updateAgent");
            setTimeout(() => {
              this.resetActionBar("toUnpinTerminals");
            }, 4000);
            return;
          }

          this.actionBarData.toUnpinTerminals.status = "ERROR";
          this.actionBarData.toUnpinTerminals.broadDescription =
            res.data.payload;
        })
        .catch((error) => {
          console.log("Error to delete terminals from agent", error);
          if (error.request.status === 403) {
            this.actionBarData.toUnpinTerminals.status = "ERROR";
            (this.actionBarData.toUnpinTerminals.action = "Сохранение"),
              (this.actionBarData.toUnpinTerminals.broadDescription =
                "Недостаточно прав");
            return;
          }
          this.actionBarData.toUnpinTerminals.status = "ERROR";
          this.actionBarData.toUnpinTerminals.broadDescription = error.data;
        });

      // .finally(() => {
      //   this.showLoad = false;
      // });
    },

    resetState() {
      this.settings.сoefficient = this.agent.rate;
      this.getAgentRules();
    },

    // reset action bar state
    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },
  },
  computed: {
    getAgent() {
      return {
        agent: this.agent.agent,
        isOnline: this.agent.isOnline,
        сoefficient: this.settings.сoefficient,
      };
    },

    getPinTerminalsToAgent() {
      const tempTerminals = [];
      if (!this.settings.pinTerminalsToAgent) {
        return [];
      }
      this.settings.pinTerminalsToAgent.split(",").forEach((el) => {
        if (el.length > 0 && !Number.isNaN(Number(el))) {
          tempTerminals.push(Number(el));
        }
      });
      return tempTerminals;
    },

    getFilteredPinTerminals() {
      if (this.filterPinnedTerminals.length > 0) {
        return this.pinnedTerminals.filter((el) =>
          this.filterPinnedTerminals.includes(el)
        );
      }
      return this.pinnedTerminals;
    },

    getFilteredConnectedTerminals() {
      if (this.filterConnectedTerminals.length > 0) {
        return this.connectedTerminals.filter((el) => {
          return this.filterConnectedTerminals.includes(el.terminalId);
        });
      }
      return this.connectedTerminals;
    },

    isValidPinTerminalsToAgent() {
      if (this.settings.pinTerminalsToAgent) {
        for (let el of this.settings.pinTerminalsToAgent.split(",")) {
          if (Number.isNaN(Number(el.trim()))) {
            return false;
          }
        }

        return true;
      }
      return true;
    },
    isValidCoefficient() {
      if (this.settings.сoefficient >= 0 && this.settings.сoefficient <= 5) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.resetState();
  },
  watch: {
    agent() {
      this.resetState();
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";

#transport_agent_panel {
  .transport_agent_info_container {
    position: absolute;
    width: 801px;
    height: 100%;
    right: 0;
    top: 72px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .action_status_container {
      min-height: 30px;
      max-height: 100px;
      display: flex;
      align-items: center;
      padding: 15px 0px 0px 25px;
    }

    .limit {
      input {
        width: 121px;
      }
    }
    .description {
      input {
        width: 307px;
      }
    }
    .default_dist {
      select {
        width: 315px;
      }
    }
  }

  .header_container {
    display: flex;
    height: 49px;
    align-items: center;
    justify-content: space-between;
    background: #d6e6ff;
    padding: 0px 25px;

    .traspor_title_container {
      display: flex;
      align-items: center;
      .status {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        margin-right: 10px;
        &.online {
          background-color: #5bc367;
        }

        &.offline {
          background-color: #f44c4b;
        }

        &.no_info {
          background-color: #a1a3ab;
        }
      }
    }

    button {
      all: unset;
    }

    .right_buttons_container {
      width: 60px;
      display: flex;
      justify-content: space-between;

      button {
        width: 27px;
        height: 27px;
        display: flex;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
      }

      button:hover {
        background-color: #4151b761;
        border-radius: 50px;
        transition: 0.3s;
      }

      button:active {
        outline-color: transparent;
        outline-style: solid;
        box-shadow: 0 0 0 6px #4150b7;
        background-color: none;
      }
    }
  }

  .transport_agent_content_container {
    padding: 25px 0px 0px 25px;
    overflow-y: scroll;
    max-height: calc(100vh - 150px);

    .transport_agent_content_container__item {
      margin-bottom: 25px;
      .item_title {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
      .item_status_bar {
        min-height: 20px;
        max-height: 100px;
        margin: 5px 0px;
      }
    }

    .not_found {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: Roboto;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
    }

    .сoefficient_container {
      display: flex;
      flex-direction: column;
      font-family: Roboto;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: left;
      color: #a1a3ab;

      .сoefficient_container__item {
        margin-bottom: 3px;
      }

      .input_container {
        display: flex;
        align-items: center;
        .label_input {
          width: 180px;
          margin-right: 10px;
        }
      }
    }

    .pin_terminals_to_agent_container {
      display: flex;
      flex-direction: column;

      .input_container {
        display: flex;
        align-items: center;
        .label_input {
          width: 244px;
          margin-right: 10px;
        }
      }
    }

    .pin_table {
      display: flex;
      @cell-margin: 10px;
      @cell-width: 130px;
      @cell-height: 35px;
      @row-number: 3;
      @column-number: 4;
      margin-top: 10px;
      height: calc(@row-number * calc(@cell-margin + @cell-height) + 20px);
      width: calc(
        calc(@column-number + 1) * calc(@cell-margin + @cell-width) + 10px
      );
      overflow-y: scroll;
      margin-bottom: 10px;
      .not_found {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .connected_table {
      height: 170px;
    }

    .pin_terminal_button_container {
      display: flex;
      margin-right: 10px;
    }
  }
}
</style>
