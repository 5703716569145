<template>
  <div id="transport_content">
    <notify-bar ref="notifyBar" />
    <dialog-window
      @submit-recalculate-config="onSubmitRecalculateConfig"
      @submit-reset-config="onSubmitResetConfig"
      @cancel="onCancelDialogWindow"
      :show="dialogWindow.show"
      :msg="dialogWindow.msg"
      :submitEvent="dialogWindow.submitEvent"
    />
    <div class="transport_content_container">
      <div class="transport_content_container__item title_container">
        <div class="title_container__item title">
          <label>Транспорт</label>
        </div>
      </div>
      <div class="transport_content_container__item">
        <transport-table
          @select-agent="onSelectAgent"
          @recalculate-config="onRecalculateConfig"
          @reset-config="onResetConfig"
          :agents="agents"
        />
        <!-- v-if="selectedDistr !== null" -->
        <transport-panel
          v-if="selectedAgent !== null"
          @update-agent="getAgents"
          @close-panel="selectedAgent = null"
          :agent="getSelectedAgent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TransportTable from "../components/TransportTable.vue";
import TransportPanel from "../components/TransportPanel.vue";
import DialogWindow from "../components/DialogWindow.vue";
import NotifyBar from "../components/NotifyBar.vue";

export default {
  components: {
    "transport-table": TransportTable,
    "transport-panel": TransportPanel,
    "dialog-window": DialogWindow,
    "notify-bar": NotifyBar,
  },
  data() {
    return {
      selectedAgent: null,
      agents: [],
      toRecalculateConfigAgent: null,
      toResetConfigAgent: null,

      toDelDistr: null,
      dialogWindow: {
        show: false,
        msg: "",
        submitEvent: "submitTerminalDelete",
      },
    };
  },
  methods: {
    getAgents() {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/agents`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy obtained (get agents)");
          if (res.data.statusCode === 200) {
            this.agents = res.data.payload.agents;
            return;
          } else {
            console.log("Response proxy error (get agents)", res.data);
          }
        })
        .catch((error) => {
          console.log("Error to get agents", error);
        });
    },
    onSelectAgent(agent) {
      this.selectedAgent = agent;
    },
    onRecalculateConfig(agent) {
      this.toRecalculateConfigAgent = agent;
      this.dialogWindow = {
        show: true,
        msg: `<br>Пересчитать конфигурации пиров для:<br><p style="text-align: center;"> <b>${agent}</b></p>`,
        submitEvent: "submitRecalculateConfig",
      };
    },
    onResetConfig(agent) {
      this.toResetConfigAgent = agent;
      this.dialogWindow = {
        show: true,
        msg: `<br>Удалить все приры для:<br><p style="text-align: center;"> <b>${agent}</b></p>`,
        submitEvent: "submitResetConfig",
      };
    },

    onSubmitResetConfig() {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Удаление пиров",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/transport/agents?agent=${this.toResetConfigAgent}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (delete terminals from agent)", res);

          if ([200, 201].includes(res.data.statusCode)) {
            this.getAgents();
            this.toResetConfigAgent = null;
            this.onCancelDialogWindow()
            this.$refs.notifyBar.addOKMsg({
              overWriteMsg: "Пиры удалены",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления пиров",
          });
        })
        .catch((error) => {
          console.log("Error to delete terminals from agent", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка удаления пиров",
          });
        });
    },
    onSubmitRecalculateConfig() {
      this.toRecalculateConfigAgent = null;
      this.dialogWindow = {
        show: false,
        msg: ``,
        submitEvent: "",
      };
    },

    onCancelDialogWindow() {
      this.dialogWindow.show = false;
    },

  },
  created() {
    this.getAgents();
  },
  computed: {
    getSelectedAgent() {
      return this.agents.find((el) => {
        return el.agent === this.selectedAgent;
      });
    },
    getDeleteDistr() {
      return this.distributives.find((el) => {
        return el.type === this.toDelDistr;
      });
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";

.transport_content_container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  .blue_button {
    height: 20px;
  }
}
</style>
